<template>
  <v-main>
    <section>
      <v-carousel
        cycle
        height="1000"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        class="center"
        :show-arrows="false"
      >
        <v-carousel-item
          v-for="(number, i) in coverimages2"
          :key="i"
          :src="number"
        >
        </v-carousel-item>
        <div
          class="text-h2 cover-title white--text text-center"
          style="font-size: 3rem !important"
        >
          ЭНЭ БОЛ МИНИЙ ТӨРСӨН НУТАГ <br />
          МОНГОЛЫН САЙXАН ОРОН
        </div>
      </v-carousel>
    </section>

    <section class="default-container">
      <v-container fluid class="text-center">
        <div class="text-h2">
          Xамгийн алдартай аялалын цэгүүд
        </div>
        <div class="app-section-subtitle">
          Шилмэл, шилдэг машрутууд таны аяллын сэтгэлийг баясгана.
        </div>

        <v-row>
          <v-col
            v-for="location in topLocations"
            v-bind:key="location.id"
            md="3"
            lg="3"
            sm="6"
            cols="12"
            align="center"
          >
            <v-card class="pb-6 my-2">
              <v-img
                v-if="location.images"
                :src="location.images[0].fileUrl"
                :aspect-ratio="1 / 1"
              ></v-img>
              <v-img v-else src="/cover/cim023.jpg"> </v-img>
              <!-- <v-card-title class="text-h5">Cafe Badilico</v-card-title> -->

              <v-card-text class="mt-2">
                <v-row align="center" class="mx-0">
                  <v-rating
                    :value="4.5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>

                  <div class="grey--text ml-4">4.5 (233)</div>
                </v-row>
              </v-card-text>
              <v-img
                :src="location.shortName?'/maps/' + location.shortName+ '.jpg':'/maps/tmp.jpg'"
                :aspect-ratio="1 / 1"
                contain
                class="mx-10 my-4"
              ></v-img>
              <div class="text-h3 mb-1" v-if="location.name">{{location.name}}</div>
              <div class="text-h3 mb-1" v-else>XXX</div>
              <div class="app-subtitle mb-4">{{ location.selectedCityName }}</div>
              <v-btn
                outlined
                tile
                class="px-6 my-2 btn-text detail-button"
                @click="_goDetail(location.ref.path)"
                ><span class="">Дэлгэрэнгүй</span></v-btn
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <Blogs></Blogs>

    <!-- <section style="height: 200px">
      <div class="v-sheet theme--light">
        <div class="container py-8">
          <div
            class="
              d-flex
              flex-column flex-lg-row
              justify-space-between
              align-center
            "
          >
            <div class="text-center text-lg-left">
              <div class="text-h3">Ready to talk?</div>
              <div class="text-h3 primary--text">Our team is here to help.</div>
            </div>
            <div class="mt-4 mt-lg-0">
              <button
                type="button"
                class="
                  my-1
                  mx-sm-2
                  w-full w-sm-auto
                  v-btn v-btn--is-elevated v-btn--has-bg
                  theme--light
                  v-size--x-large
                  primary
                "
              >
                <span class="v-btn__content">Contact Sales</span></button
              ><button
                type="button"
                class="
                  my-1
                  w-full w-sm-auto
                  v-btn v-btn--is-elevated v-btn--has-bg
                  theme--light
                  v-size--x-large
                "
              >
                <span class="v-btn__content">Learn more</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="shop-container text-center">
      <div class="text-h2">Аяллын бүтээгдэxүүн</div>
      <div class="app-section-subtitle">
        We are the Backpack Traveler, your favorite travel assistants!
      </div>
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="2"
            lg="2"
            sm="6"
            v-for="(book, bindex) in books"
            :key="bindex"
          >
            <v-card flat color="transparent">
              <v-img :src="'/book1.png'"></v-img>
              <div class="text-h4">Govi-Altai</div>
              <div class="app-subtitle">dfsldfjskdjf</div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
    <Books> </Books>
    <products></products>

    <Zar></Zar>
    <Books> </Books>
    <Experts></Experts>
    <Reviews></Reviews>

    <Membership></Membership>

    <organizations></organizations>
    <section>
      <div class="bg-grey-light py-16">
        <div class="container">
          <div class="d-flex justify-center flex-wrap">
            <v-img src="mobileapp.png" max-width="385px" class="mr-16"></v-img>
            <div class="d-flex flex-column mt-16">
              <h1 class="text-40 mb-0">Get the Mobile App</h1>
              <p class="text-18 grey--text text--darken-1 mb-0">
                We will send you a link, open it on your phone to download the
                app
              </p>
              <div
                class="
                  v-input
                  mb-4
                  v-input--hide-details
                  theme--light
                  v-input--selection-controls
                  v-input--radio-group
                  v-input--radio-group--row
                "
              >
                <div class="v-input__control">
                  <div class="v-input__slot" style="height: auto">
                    <div
                      role="radiogroup"
                      aria-labelledby="input-1887"
                      class="v-input--radio-group__input"
                    >
                      <div class="v-radio theme--light">
                        <div class="v-input--selection-controls__input">
                          <i
                            aria-hidden="true"
                            class="
                              v-icon
                              notranslate
                              mdi mdi-radiobox-blank
                              theme--light
                            "
                          ></i
                          ><input
                            aria-checked="false"
                            id="input-255"
                            role="radio"
                            type="radio"
                            name="radio-254"
                            value="radio-1"
                          />
                          <div
                            class="v-input--selection-controls__ripple"
                          ></div>
                        </div>
                        <label
                          for="input-255"
                          class="v-label theme--light"
                          style="left: 0; right: auto; position: relative"
                          >Email</label
                        >
                      </div>
                      <div class="v-radio theme--light">
                        <div class="v-input--selection-controls__input">
                          <i
                            aria-hidden="true"
                            class="
                              v-icon
                              notranslate
                              mdi mdi-radiobox-blank
                              theme--light
                            "
                          ></i
                          ><input
                            aria-checked="false"
                            id="input-257"
                            role="radio"
                            type="radio"
                            name="radio-254"
                            value="radio-2"
                          />
                          <div
                            class="v-input--selection-controls__ripple"
                          ></div>
                        </div>
                        <label
                          for="input-257"
                          class="v-label theme--light"
                          style="left: 0; right: auto; position: relative"
                          >Phone</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center mb-8">
                <div
                  class="
                    v-input
                    rounded-r-0
                    border-r-n-grey
                    v-input--hide-details v-input--dense
                    theme--light
                    v-text-field
                    v-text-field--single-line
                    v-text-field--solo
                    v-text-field--solo-flat
                    v-text-field--is-booted
                    v-text-field--enclosed
                    v-text-field--placeholder
                  "
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <input id="input-259" placeholder="Email" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <v-btn
                  elevation="0"
                  class="
                    rounded-l-0
                    py-5
                    v-btn--has-bg
                    theme--light
                    v-size--default
                    bg-info-grediant
                    white--text
                  "
                >
                  <span class="v-btn__content"> Subscribe! </span>
                </v-btn>
              </div>
              <h6 class="mb-3 grey--text font-weight-regular">
                Download App from
              </h6>
              <div class="d-flex flex-wrap">
                <div
                  class="v-avatar me-4 rounded-0"
                  style="height: 50px; min-width: 48px; width: 150px"
                >
                  <img src="/homes/playstore.png" alt="" />
                </div>
                <div
                  class="v-avatar rounded-0"
                  style="height: 50px; min-width: 48px; width: 150px"
                >
                  <img src="/homes/appstore.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<style>
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
</style>

<script>
import Organizations from "./sections/Organizations.vue";
import Blogs from "./sections/Blogs.vue";
import Populars from "./sections/Populars.vue";
import Zar from "./sections/Zar.vue";
import Experts from "./sections/Experts.vue";
import Reviews from "./sections/Reviews.vue";
import Membership from "./sections/Membership.vue";
import Products from "./sections/Products.vue";
import Books from "./sections/Books.vue";
const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    topLocations: null,
    coverimages2: [
      "https://live.staticflickr.com/65535/51730984819_3dbc53ba5b_k.jpg",
      "https://live.staticflickr.com/65535/51727803011_87e525d203_b.jpg",
      "https://live.staticflickr.com/65535/51728054538_9b640bac50_b.jpg",
      "https://live.staticflickr.com/65535/51706554109_c5e0ae6079_b.jpg",
      "https://live.staticflickr.com/65535/51706160918_3bd32ac84b_b.jpg",
      "https://live.staticflickr.com/65535/51705850015_87e541f729_b.jpg",
      "https://live.staticflickr.com/65535/51704960406_076868bc98_b.jpg",
    ],
    coverimages: [
      "01",
      "02",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "12",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    drawer: false,
    group: null,
    items: ["Home", "Гишүүнчлэл", "Бидний туxай"],
    items1: ["News", "Events", "Academics"],
    books: [
      {
        name: "МОНГОЛЫН НУУЦ ТОВЧООН",
        price: "29,900₮",
        image: "book1.png",
        url: "http://www.monsudar.mn/books/view/589/",
      },
      {
        name: "ХАЙРХААН",
        price: "20,000₮",
        image: "book2.png",
        url: "https://www.internom.mn/%D0%BD%D0%BE%D0%BC/%D1%85%D0%B0%D0%B9%D1%80%D1%85%D0%B0%D0%B0%D0%BD",
      },
      {
        name: "МОНГОЛЫН НУУЦ ТОВЧООН",
        price: "29,900₮",
        image: "book1.png",
        url: "http://www.monsudar.mn/books/view/589/",
      },
      {
        name: "МОНГОЛЫН НУУЦ ТОВЧООН",
        price: "29,900₮",
        image: "book1.png",
        url: "http://www.monsudar.mn/books/view/589/",
      },
      {
        name: "МОНГОЛЫН НУУЦ ТОВЧООН",
        price: "29,900₮",
        image: "book1.png",
        url: "http://www.monsudar.mn/books/view/589/",
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  components: {
    Organizations,
    Blogs,
    Populars,
    Zar,
    Experts,
    Reviews,
    Membership,
    Products,
    Books,
  },
  methods: {
    _goDetail(xpath) {
      this.$router.push({
        name: "TourDetail",
        params: { itemId: xpath },
      });
    },
  },
  created() {
    fb.db
      .collection("/countries/Mongolia/locations")
      .onSnapshot((querySnapshot) => {
        this.topLocations = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " x=> ", doc.data());
          let location = doc.data();
          location.id = doc.id;
          location.ref = doc.ref;

          this.topLocations.push(location);
          console.log(this.topLocations);
        });
      });
  },
};
</script>

<style>
.bg-extra-light {
  background-color: #f4f8fa;
}

.mini-spacer {
  padding: 75px 0;
}
.text-40 {
  font-size: 40px;
}
.bg-grey-light {
  background-color: #f6f9fc;
}
</style>