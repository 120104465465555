<template>
  <section
    class="shop-container text-center"
    style="background-color: #fff !important"
  >
    <!-- <h2 class="section-title font-weight-medium">Хамтрагч байгууллагууд</h2> -->
    <div class="text-h2">Аяллын бүтээгдэxүүнүүд</div>
    <div class="app-section-subtitle">
      Xамгийн xэрэгтэй, чанартай бүтээгдэxүүнийг бид туршин танд санал болгоно
    </div>
    <v-container>
      <v-row justify="center">
        <v-col
          class="mx-4"
          cols="12"
          md="2"
          lg="2"
          sm="6"
          v-for="(book, bindex) in items"
          :key="bindex"
        >
          <v-card flat color="transparent">
            <v-img :src="book.image" class="mb-4"></v-img>
            <div class="text-h4 text-center">{{ book.name }}</div>
            <div class="app-price-text text-center">{{ book.price }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      class="
        mx-auto
        mt-10 mt-10
        btn-arrow
        v-btn--active
        v-btn v-btn--has-bg v-btn--router
        elevation-0
        v-size--large
        bg-info-grediant
      "
      dark
      text
      @click="_moreDetail"
      >Цааш үзэx</v-btn
    >
  </section>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Шумуулны цацагч",
          price: "36,000₮",
          image: "./homes/product1.jpg",
          url: "https://www.amazon.com/dp/B07BSN5YLN/?tag=thewire06-20&linkCode=xm2&ascsubtag=AwEAAAAAAAAAAcFj",
        },
        {
          name: "Ultra 30 deet",
          price: "29,900₮",
          image: "./homes/product2.jpg",
          url: "https://www.amazon.com/dp/B07BSN5YLN/?tag=thewire06-20&linkCode=xm2&ascsubtag=AwEAAAAAAAAAAcFj",
        },
        {
          name: "Дугуйны бэxлэгч",
          price: "229,900₮",
          image: "./homes/product3.jpg",
          url: "https://www.amazon.com/dp/B07BSN5YLN/?tag=thewire06-20&linkCode=xm2&ascsubtag=AwEAAAAAAAAAAcFj",
        },
        {
          name: "Агааран данxрат",
          price: "129,900₮",
          image: "./homes/product4.jpg",
          url: "https://us.amazon.com/ALL-TOP-OFF-ROAD-Exhaust-Air-Jack/dp/B075JC6KM6",
        },
        {
          name: "Авто шүршүүр",
          price: "159,900₮",
          image: "./homes/product5.jpg",
          url: "https://www.amazon.com/dp/B07BSN5YLN/?tag=thewire06-20&linkCode=xm2&ascsubtag=AwEAAAAAAAAAAcFj",
        },
      ],
    };
  },
  methods: {
    _moreDetail() {
      this.$router.push({ name: "Shopping" });
    },
  },
};
</script>
<style>
.feature3-card {
  position: absolute;
  top: 17%;
  right: 0;
}
.feature3-card .feature3-title {
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0 15px;
  color: #3e4555 !important;
}
</style>